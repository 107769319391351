<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <label>
          <span>{{ $t('addListing.discount.title') }}</span>
        </label>
      </div>
      <div class="right--col">
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: showDiscount }"
          @click="showDiscount = true"
        >
          {{ $t('general.yes') }}
        </button>
        <button
          type="button"
          class="btn px-4"
          :class="{ selected: !showDiscount }"
          @click="showDiscount = false"
        >
          <span style="position: relative;">
            {{ $t('general.no') }}
          </span>
        </button>
        <!--      <div-->
        <!--        :class="{ 'has-error': validation.hasError('discountPercentage') }"-->
        <!--        class="form-group row"-->
        <!--      >-->
        <!--        <div class="col-sm-3 col-xs-12 mb-1 mb-sm-0">-->
        <!--          <div class="custom-control custom-checkbox my-2">-->
        <!--            <input-->
        <!--              type="checkbox"-->
        <!--              class="custom-control-input"-->
        <!--              v-model="showDiscount"-->
        <!--              id="show-discount-rent"-->
        <!--            />-->
        <!--            <label class="custom-control-label" :for="'show-discount-rent'">{{-->
        <!--              $t('addListing.discount.question')-->
        <!--            }}</label>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-sm-9 col-xs-12">-->
        <!--          <div class="input-group">-->
        <!--            <cleave-->
        <!--              class="basic--input"-->
        <!--              type="text"-->
        <!--              name="discount_percentage"-->
        <!--              v-model="discountPercentage"-->
        <!--              :class="validation.hasError('discountPercentage') ? 'is-invalid' : ''"-->
        <!--              :options="cleaveOptionPercentage"-->
        <!--            />-->
        <!--            <div class="input-group-append">-->
        <!--              <div class="input-group-text">%</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div v-show="showDiscount" class="val-error">-->
        <!--            {{ validation.firstError('discountPercentage') }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      <span class="val-error">{{ validation.firstError('selectedRentPeriodIds') }}</span>-->
      </div>
    </div>
    <div v-show="showDiscount">
      <div class="form--group" :class="{ 'has-error': validation.hasError('discountPercentage') }">
        <div class="left--col"></div>
        <div class="right--col">
          <div class="input--group">
            <cleave
              class="basic--input discount--input"
              type="text"
              name="discount_percentage"
              v-model="discountPercentage"
              :class="validation.hasError('discountPercentage') ? 'is-invalid' : ''"
              :options="cleaveOptionPercentage"
            />
            <div class="append--right">
              %
            </div>
          </div>
          <span
            v-show="showDiscount"
            class="val-error"
            v-if="validation.hasError('discountPercentage')"
            >{{ validation.firstError('discountPercentage') }}</span
          >
        </div>
      </div>

      <div class="form--group" v-for="rentPrice in selectedRentPrices" :key="rentPrice.id">
        <label class="left--col"></label>
        <div class="right--col" v-show="discountPercentage">
          <div class="mb-1 preview--title">
            {{ rentPrice.name }}
          </div>
          <discount-preview
            :real-price="`${rentPrice.price}`"
            :discountedPriceTitle="`${$t('addListing.discount.finalPrice')} ${rentPrice.name}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component';
import { Validator } from 'simple-vue-validator';
const DiscountPreview = () =>
  import('@/components/listing-form/sect1/discount/discount-preview.vue');

export default {
  name: 'discount-rent-section',
  components: { Cleave, DiscountPreview },
  data() {
    return {
      cleaveOptionPrice: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      cleaveOptionPercentage: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 2,
        numeralDecimalScale: 2,
      },
    };
  },
  computed: {
    showDiscount: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.showDiscount;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SHOW_DISCOUNT', value);
      },
    },
    isDiscPercentage: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.isDiscPercentage;
      },
      set(val) {
        this.discountPercentage = null;
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_IS_DISC_PERCENTAGE', val);
      },
    },
    discountPercentage: {
      get() {
        return this.$store.state.v2.listingForm.sect1.basicInfo.discountPercentage;
      },
      set(val) {
        this.$store.commit('v2/listingForm/sect1/basicInfo/SET_DISCOUNT_PERCENTAGE', val);
      },
    },
    selectedRentPrices() {
      return this.$store.getters['v2/listingForm/sect1/basicInfo/selectedRentPrices'];
    },
  },
  validators: {
    discountPercentage(value) {
      if (!this.showDiscount) return null;
      return Validator.value(value)
        .required(this.$i18n.t('errors.addListing.discount.required'))
        .between(
          1,
          99.99,
          this.$i18n.t('errors.addListing.discount.between', {
            minChar: '1%',
            maxChar: '99,99%',
          }),
        );
    },
  },
  methods: {
    validate: function() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped></style>
